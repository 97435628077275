import React from "react";
import { motion, transform } from "framer-motion";

import "./hero.component.css";

import WaterDropGrid from "./herobackground.component";

export default class Hero extends React.Component{

    greeting = "Hello, I am".split("");
    name = "Bhavik Makhija".split("");
    role = "A Computer Science Student at University of Waterloo".split("");

    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(1.4,.55,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(1.15,.85,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1.05,.95,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff"],
    };}

    render(){
        return (
            
        <div>
            <div className="hero-background">
            <WaterDropGrid />
            </div>
            <div className="hero-text">
            {this.greeting.map((letter, index) => {
                return <motion.span  whileHover={() => this.bandy()} key={index} className="greeting">{
                    letter === " " ? "\u00A0" : letter}</motion.span>;
            } )}
            <br />
            {this.name.map((letter, index) => {
                return <motion.span  whileHover={() => this.bandy()} key={index} className="name">{
                    letter === " " ? "\u00A0" : letter}</motion.span>;
            } )}
            <br />
            {this.role.map((letter, index) => {
                return <motion.span  whileHover={() => this.bandy()} key={index} className="role">{
                    letter === " " ? "\u00A0" : letter}</motion.span>;
            } )}
            
            
        </div>
        </div>
    );
  }
}

