import React from "react";
import { motion, transform } from "framer-motion";
import {Card, CardBody, Progress} from "@nextui-org/react";

export default class myAwards extends React.Component{
    
    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1,1)",
            "scale3d(.95,1,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    render() {
        return (
            <div className="Wrapper">
                <motion.h1 className="text-3xl font-bold text-white text-center my-10" whileHover={() => this.bandy()}>Achievements</motion.h1>
                <div className="flex items-stretch content-center place-content-center">
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 mx-20 w-1/5"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1  text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            Awards
                        </motion.h1>
                        
                        <motion.ul className="text-white list-disc ml-10"  whileHover={() => this.bandy()}>
                            <li> Euclid Math Contest </li>
                            <li> Canadian Computing Contest</li>
                            <li> Fermat Math Contest</li>
                            <li> Hypatia Math Contest</li>
                        </motion.ul>
                    </CardBody>
                </Card>
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 mx-20 w-1/5"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            Scholarships
                        </motion.h1>
                        <motion.ul className="text-white list-disc ml-10"  whileHover={() => this.bandy()}>
                            <li> President’s Scholarship of Distinction </li>
                            <li> American Express Scholarship </li>
                            <li> Purolator Scholarship </li>
                        </motion.ul>
                    </CardBody>
                </Card>
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 mx-20 w-1/5"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            Hackathons
                        </motion.h1>
                        <motion.ul className="text-white list-disc ml-10"  whileHover={() => this.bandy()}>
                            <li> Hack The Valley </li>
                            <li> HackRX </li>
                            <li> Hack The North </li>
                        </motion.ul>
                    </CardBody>
                </Card>
                </div>
            </div>
        );
    }
}