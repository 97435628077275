import React from "react";
import { motion, transform } from "framer-motion";
import { Button } from "@nextui-org/react";

import BhavikResume from "../Components/img/BhavikMakhijaResume.pdf";

export default class ContactMe extends React.Component{

    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(1.4,.55,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(1.15,.85,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1.05,.95,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff"],
    };}

    render(){
        return (
        <div className="h-screen v-screen">
            <motion.h1 className="text-3xl font-bold text-white text-center my-10" whileHover={() => this.bandy()}>Contact Me</motion.h1>
        <div className="flex flex-col justify-center items-center h-4/5">
            <motion.h1 className="text-5xl font-bold text-white text-center" whileHover={() => this.bandy()}>Linkedin: bhavik-makhija</motion.h1>
            <motion.h1 className="text-5xl font-bold text-white text-center" whileHover={() => this.bandy()}>Email: bmakhija@uwaterloo.ca</motion.h1>
            <a 
                href={BhavikResume}
                download="Bhavik-Resume"
                target="_blank"
                rel="noreferrer">
            <Button className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2] text-white text-2xl my-5" shadow="sm" size=";g" onClick={() => window.open("https://www.linkedin.com/in/bhavik-makhija", "_blank")}>Download CV</Button>
            </a>
        </div>
        </div>
    );
  }
}

