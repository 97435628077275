import React from "react";
import { motion, transform } from "framer-motion";
import {Card, CardBody, Progress} from "@nextui-org/react";

export default class mySkills extends React.Component{
    
    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    render() {
        return (
            <div className="Wrapper">
                <motion.h1 className="text-3xl font-bold text-white text-center my-10" whileHover={() => this.bandy()}>Skills</motion.h1>
                <div className="flex items-stretch content-center place-content-center">
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 mx-20 w-1/5"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1  text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            Programming Skills 
                        </motion.h1>
                        <Progress
                            size="sm"
                            radius="sm"
                            classNames={{
                              base: "max-w-md",
                              track: "drop-shadow-md border border-default",
                              indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                              label: "tracking-wider font-medium",
                              value: "invisible",
                            }}
                            label="C++"
                            value={80}
                            showValueLabel={true}
                        />
                        <Progress
                            size="sm"
                            radius="sm"
                            classNames={{
                              base: "max-w-md",
                              track: "drop-shadow-md border border-default",
                              indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                              label: "tracking-wider font-medium",
                              value: "invisible",
                            }}
                            label="C"
                            value={80}
                            showValueLabel={true}
                        />
                        <Progress
                            size="sm"
                            radius="sm"
                            classNames={{
                              base: "max-w-md",
                              track: "drop-shadow-md border border-default",
                              indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                              label: "tracking-wider font-medium",
                              value: "invisible",
                            }}
                            label="Java"
                            value={60}
                            showValueLabel={true}
                        />
                        <Progress
                            size="sm"
                            radius="sm"
                            classNames={{
                              base: "max-w-md",
                              track: "drop-shadow-md border border-default",
                              indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                              label: "tracking-wider font-medium",
                              value: "invisible",
                            }}
                            label="JavaScript"
                            value={60}
                            showValueLabel={true}
                        />
                        <Progress
                            size="sm"
                            radius="sm"
                            classNames={{
                              base: "max-w-md",
                              track: "drop-shadow-md border border-default",
                              indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                              label: "tracking-wider font-medium",
                              value: "invisible",
                            }}
                            label="Python"
                            value={40}
                            showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Bash"
                        value={40}
                        showValueLabel={true}
                        />
                    </CardBody>
                </Card>
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 mx-20 w-1/5"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            Technical Skills
                        </motion.h1>
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Git"
                        value={80}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Azure DevOps"
                        value={80}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Linux"
                        value={70}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="SQL"
                        value={60}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Jira"
                        value={60}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Docker"
                        value={40}
                        showValueLabel={true}
                        />
                    </CardBody>
                </Card>
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 mx-20 w-1/5"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            Other Skills
                        </motion.h1>
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Willingness to learn"
                        value={100}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Collaboration"
                        value={90}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Growth Mindset"
                        value={90}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Critical thinking"
                        value={80}
                        showValueLabel={true}
                        />
                        <Progress
                        size="sm"
                        radius="sm"
                        classNames={{
                          base: "max-w-md",
                          track: "drop-shadow-md border border-default",
                          indicator: "bg-gradient-to-r from-cyan-500 to-blue-500",
                          label: "tracking-wider font-medium",
                          value: "invisible",
                        }}
                        label="Time management"
                        value={70}
                        showValueLabel={true}
                        />
                    </CardBody>
                </Card>
                </div>
            </div>
                
        );
    }
}