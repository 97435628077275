import anime from "animejs";
import React, { useRef, useEffect } from "react";

const WaterDropGrid = () => {
  return (
    <div>
      <DotGrid > 
     </DotGrid>
    </div>
  );
};

const DotGrid = () => {

    const GRID_WIDTH = 23;
    const GRID_HEIGHT = 12;

  const handleDotClick = (e) => {
    anime({
      targets: ".dot-point",
      scale: [
        { value: 1.35, easing: "easeOutSine", duration: 250 },
        { value: 1, easing: "easeInOutQuad", duration: 500 },
      ],
      translateY: [
        { value: -15, easing: "easeOutSine", duration: 250 },
        { value: 0, easing: "easeInOutQuad", duration: 500 },
      ],
      opacity: [
        { value: 1, easing: "easeOutSine", duration: 250 },
        { value: 0.2, easing: "easeInOutQuad", duration: 500 },
      ],
      delay: anime.stagger(100, {
        grid: [GRID_WIDTH, GRID_HEIGHT],
        from: e.target.dataset.index,
      }),
    });
    };

    const handleDotEffect = () => {
        anime({
          targets: ".dot-point",
          scale: [
            { value: 1.35, easing: "easeOutSine", duration: 250 },
            { value: 1, easing: "easeInOutQuad", duration: 500 },
          ],
          translateY: [
            { value: -15, easing: "easeOutSine", duration: 250 },
            { value: 0, easing: "easeInOutQuad", duration: 500 },
          ],
          opacity: [
            { value: 0.5, easing: "easeOutSine", duration: 250 },
            { value: 0.2, easing: "easeInOutQuad", duration: 500 },
          ],
          delay: anime.stagger(200, {
            grid: [GRID_WIDTH, GRID_HEIGHT],
            from: "center",
          }),
          loop: true,
        });
        };

  const dots = [];
  let index = 0;

  for (let i = 0; i < GRID_WIDTH; i++) {
    for (let j = 0; j < GRID_HEIGHT; j++) {
      dots.push(
        <div
          className="group p-0 transition-colors hover:bg-slate-600"
          data-index={index}
          key={`${i}-${j}`}
        >
          <div
            className="dot-point h-20 w-20 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 group-hover:from-indigo-600 group-hover:to-white opacity-50"
            data-index={index}
          />
        </div>
      );
      index++;
    }
  }

  return (
    <div
      onClick={handleDotClick}
      onMouseEnter={handleDotEffect}
      style={{ gridTemplateColumns: `repeat(${GRID_WIDTH}, 1fr)`,  opacity: 0.15, maxWidth: "100%",}}
      className="grid w-fit"
    >
      {dots}
    </div>
  );
};

export default WaterDropGrid;