import React from "react";
import {Card, CardBody, Image, Button, Slider, CardFooter} from "@nextui-org/react";
import {  motion,transform } from "framer-motion";
import picture from "./img/bhavik.JPG";

export default class AboutMe extends React.Component{

  bandy = () => {
    return {
    transform : [
        "scale3d(1,1,1)",
        "scale3d(.75,1.25,1)",
        "scale3d(.95,1.05,1)",
        "scale3d(1,1,1)",
    ],
    transition: {duration: 0.3},
    color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
  };} 

render(){
  return (
    <Card
      isBlurred={true}
      className="border-none bg-background dark:bg-default-100/50 mx-10 my-10"
      shadow="sm"
    >
      <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
      <motion.h1 className="text-3xl font-bold text-white text-center" whileHover={() => this.bandy()}>About Me</motion.h1>
      <div className="wrapper">
      <Card
        isFooterBlurred
        radius="lg"
        className="border-none w-1/6 float-left mr-10">
      <Image
        alt="Woman listing to music"
        className="object-cover"
        src={picture}
        width={300}
        height={300}
      />
      <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 
      overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
        <motion.p className="text-tiny text-white/80" whileHover={() => this.bandy()}>Bhavik Makhija</motion.p>
      </CardFooter>
    </Card>
        <motion.p className="text-white my-5 mr-10 text-justify indent-5"  whileHover={() => this.bandy()}>
          Hello, I'm Bhavik Makhija, currently immersed in the captivating realm of computer science at the prestigious University of Waterloo. The seeds of my computing journey were planted back in high school, where I first encountered the world of computer science and felt the spark that would fuel my passion.
        </motion.p>
        <motion.p className="text-white my-5 mr-10 text-justify indent-5"  whileHover={() => this.bandy()}>
          The allure of leveraging technology hand-in-hand with problem-solving skills to address tangible issues and create a positive impact on society has always captivated me. More recently, I've been fortunate to collaborate with industry leaders, contributing to the development of cutting-edge technology that eases operational challenges for their companies. These experiences have equipped me with valuable hands-on knowledge, complementing my theoretical foundation and empowering me to confront complex problems with impactful solutions.
        </motion.p>
        <motion.p className="text-white my-5 mr-10 text-justify indent-5"  whileHover={() => this.bandy()}>
          Fueled by my unwavering passion for problem-solving and computing, I eagerly anticipate continuing my journey in software development. My dedication to continuous growth is evident in my enthusiasm for overcoming challenges and my commitment to acquiring both theoretical knowledge and practical skills. As I progress through university, I aspire to emerge with a profound mastery of software development, aiming to apply this expertise to contribute meaningfully to real-world projects.
        </motion.p>
        </div>
      </CardBody>
    </Card>
);}
}
