import React from "react";
import {Popover, PopoverTrigger, PopoverContent, Button, Card, CardBody, CardFooter, Image} from "@nextui-org/react";
import {  motion,transform } from "framer-motion";

import agco from "./img/AGCO.jpg";
import daneshbaz from "./img/DaneshBaz.png";
import kumon from "./img/Kumon.png";

export default class MyPopover extends React.Component {

    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(1,.55,1)",
            "scale3d(.75,1,1)",
            "scale3d(1,.85,1)",
            "scale3d(.95,1,1)",
            "scale3d(1,.95,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#FFFFFF"],
    };}



    render() {
        return (
            <div className="Wrapper">
                <motion.h1 className="text-3xl font-bold text-white text-center" whileHover={() => this.bandy()}>Experience</motion.h1>
            <Popover 
            showArrow
            backdrop="opaque"
            placement="right"
            color="secondary"
            classNames={{
              base: [ 
              ],
              content: [
                "bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]",
              ],
            }}
          >
            <PopoverTrigger className="inset-x-1/4 mt-10">
                    <Card
                        isFooterBlurred
                        radius="lg"
                        className="border-none w-1/6">
                    <Image
                        alt="Woman listing to music"
                        className="object-cover"
                        src={agco}
                    />
                        <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 
                            overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <motion.p className="text-tiny text-white/80" whileHover={() => this.bandy()}>Alcohol and Gaming Commission of Ontario</motion.p>
                        </CardFooter>
                    </Card>
            </PopoverTrigger>
            <PopoverContent>
              {(titleProps) => (
                <div>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                        Alcohol and Gaming Commission of Ontario
                    </motion.h1>
                    <motion.p className="text-white text-justify text-lg"  whileHover={() => this.bandy()}>
                        Software Test Specialist
                    </motion.p>
                    <motion.p className="text-white text-justify italic"  whileHover={() => this.bandy()}>
                        Jan 2024 – Apr 2024
                    </motion.p>
                    <motion.ul className="text-white text-justify list-disc ml-10"  whileHover={() => this.bandy()}>
                        <li>Engineered and implemented automated testing solutions, encompassing test cases and workflows, by leveraging expertise in the Software Development Life Cycle (SDLC) and Agile methodologies.</li>
                        <li>Conducted thorough software testing on internal systems, adhering to predefined test plans and program schedules, successfully identifying and troubleshooting root causes of test case failures and bugs.</li>
                        <li>Streamlined the testing process by seamlessly integrating automated tests into the Continuous Integration (CI) pipeline using Git and Azure DevOps, resulting in a significant reduction of redundant testing hours.</li>
                        <li>Collaborated cross-functionally with Business Analysts (BA) and Development teams to meticulously design detailed test cases aligned with project requirements, ensuring comprehensive coverage of software functionalities.</li>
                    </motion.ul>
                </div>
              )}
            </PopoverContent>
          </Popover>
          <Popover 
            showArrow
            backdrop="opaque"
            placement="left"
            color="secondary"
            classNames={{
              base: [ 
              ],
              content: [
                "bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]",
              ],
            }}
          >
            <PopoverTrigger className="inset-x-1/2 mt-10">
                    <Card
                        isFooterBlurred
                        radius="lg"
                        className="border-none w-1/6">
                    <Image
                        alt="Woman listing to music"
                        className="object-cover"
                        src={daneshbaz}
                    />
                        <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 
                            overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <motion.p className="text-tiny text-white/80" whileHover={() => this.bandy()}>DaneshBaz</motion.p>
                        </CardFooter>
                    </Card>
            </PopoverTrigger>
            <PopoverContent>
              {(titleProps) => (
                <div>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                        DaneshBaz 
                    </motion.h1>
                    <motion.p className="text-white text-justify text-lg"  whileHover={() => this.bandy()}>
                        Software Developer Intern
                    </motion.p>
                    <motion.p className="text-white text-justify italic"  whileHover={() => this.bandy()}>
                        May 2023 – Aug 2023
                    </motion.p>
                    <motion.ul className="text-white text-justify list-disc ml-10"  whileHover={() => this.bandy()}>
                        <li>Devised and assembled a notification system using RESTful APIs, enabling personalized email communication with users, resulting in a 43% improved engagement.</li>
                        <li>Contributed to the creation of a suggestion system employing vector databases and LLMs, enhancing user experience through personalized content recommendations.</li>
                        <li>Gained hands-on experience in agile development, testing methodologies, and API integrations while working on user-centric software solutions.</li>
                        <li>Designed and implemented automated tests utilizing Cypress, seamlessly integrating them into the CI pipeline to ensure rigorous code validation and prevent regressions, ultimately achieving 81% test coverage.</li>
                    </motion.ul>
                </div>
              )}
            </PopoverContent>
          </Popover>
          <Popover 
            showArrow
            backdrop="opaque"
            placement="right"
            color="secondary"
            classNames={{
              base: [ 
              ],
              content: [
                "bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]",
              ],
            }}
          >
            <PopoverTrigger className="inset-x-1/4 mt-10">
                    <Card
                        isFooterBlurred
                        radius="lg"
                        className="border-none w-1/6">
                    <Image
                        alt="Woman listing to music"
                        className="object-cover"
                        src={kumon}
                    />
                        <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 
                            overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 shadow-small ml-1 z-10">
                            <motion.p className="text-tiny text-white/80" whileHover={() => this.bandy()}>Kumon</motion.p>
                        </CardFooter>
                    </Card>
            </PopoverTrigger>
            <PopoverContent>
              {(titleProps) => (
                <div>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                        Kumon Institute Education
                    </motion.h1>
                    <motion.p className="text-white text-justify text-lg"  whileHover={() => this.bandy()}>
                        Teaching Assistant 
                    </motion.p>
                    <motion.p className="text-white text-justify italic"  whileHover={() => this.bandy()}>
                        May 2018 – Aug 2022
                    </motion.p>
                    <motion.ul className="text-white text-justify list-disc ml-10"  whileHover={() => this.bandy()}>
                        <li>Provided one-on-one and small group instruction to students who required extra support, utilizing individualized learning plans and strategies to meet their specific needs and help them achieve academic success.</li>
                        <li>Assisted with various administrative tasks, including maintaining student records, communicating results with parents and managers, preparing reports, and organizing/managing student assignments.           </li>
                    </motion.ul>
                </div>
              )}
            </PopoverContent>
          </Popover>
            </div>
        );
      }
    }