import React from "react";
import {  motion,transform } from "framer-motion";
import {Card, CardBody} from "@nextui-org/react";

export default class MyPopover extends React.Component {

    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    render() {
        return (
            <div>
                <motion.h1 className="text-3xl font-bold text-white text-center my-10" whileHover={() => this.bandy()}>Education</motion.h1>
                <Card
                    isBlurred={true}
                    className="border-none bg-background dark:bg-default-100/50 my-10 w-1/2 inset-x-1/4"
                    shadow="sm"
                    >
                    <CardBody className="bg-gradient-to-br from-indigo-500/[0.2] via-purple-500/[0.2] to-pink-500/[0.2]">   
                        <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-xl"  whileHover={() => this.bandy()}>
                            University of Waterloo
                        </motion.h1>
                        <motion.p className="text-white text-justify text-lg"  whileHover={() => this.bandy()}>
                            Bachelor of Computer Science
                        </motion.p>
                        <motion.p className="text-white text-justify italic"  whileHover={() => this.bandy()}>
                            September 2022 - April 2027
                        </motion.p>
                        <motion.p className="text-white text-justify italic ml-5"  whileHover={() => this.bandy()}>
                            Relativent Courses: 
                        </motion.p>
                        <motion.ul className="text-white text-justify list-disc ml-14"  whileHover={() => this.bandy()}>
                            <li> Elementary Algorithm Design and Data Abstraction</li>
                            <li> Designing Functional Programs</li>
                            <li> Logic and Computation</li>
                            <li> Object-Oriented Software Development</li>
                            <li> Computer Organization and Design</li>
                        </motion.ul>
                    </CardBody>
                </Card>
            </div>
                
        );
    }
}

                
