import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import {  motion,transform } from "framer-motion";

export default class MyNavbar extends React.Component{


  bandy = () => {
    return {
    transform : [
        "scale3d(1,1,1)",
        "scale3d(1.4,.55,1)",
        "scale3d(.75,1.25,1)",
        "scale3d(1.15,.85,1)",
        "scale3d(.95,1.05,1)",
        "scale3d(1.05,.95,1)",
        "scale3d(1,1,1)",
    ],
    transition: {duration: 0.3},
    color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff"],
  };} 

    render() {
        return (
            <Navbar shouldHideOnScroll>
            <NavbarBrand>
              <motion.p className="dark font-bold text-inherit" whileHover={() => this.bandy()}>Bhavik Makhija</motion.p>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
              <NavbarItem>
                <Link color="foreground" href="#AboutMe">
                  <motion.p whileHover={() => this.bandy()}>About Me</motion.p>
                </Link>
              </NavbarItem>
              <NavbarItem >
                <Link color="foreground" href="#Experience">
                  <motion.p whileHover={() => this.bandy()}>Experience</motion.p>
                </Link>
              </NavbarItem>
              <NavbarItem>
                <Link color="foreground" href="#Education">
                  <motion.p whileHover={() => this.bandy()}>Education</motion.p>
                </Link>
              </NavbarItem>
              <NavbarItem>
                <Link color="foreground" href="#Projects">
                  <motion.p whileHover={() => this.bandy()}>Projects</motion.p>
                </Link>
              </NavbarItem>
              <NavbarItem>
                <Link color="foreground" href="#Hobbies">
                  <motion.p whileHover={() => this.bandy()}>Hobbies</motion.p>
                </Link>
              </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" href="#Awards">
                      <motion.p whileHover={() => this.bandy()}>Awards</motion.p>
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" href="#ContactMe">
                      <motion.p whileHover={() => this.bandy()}>Contact Me</motion.p>
                    </Link>
                </NavbarItem>
            </NavbarContent>
            
          </Navbar>
        );
      }
    }
