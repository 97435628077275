import React from "react";
import { transform, motion } from "framer-motion";
import { Card, CardFooter, Image } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";

import chessPicture from "../Components/img/Chess.png";
import SuggestionPicture from "../Components/img/suggestionSystem.png";
import DocuMorphPicture from "../Components/img/DocuMorph.png";
import ExercisePicture from "../Components/img/ExerciseTracker.png";
import pictionaryPicture from "../Components/img/pictionary.png";
import webCrawlerPicture from "../Components/img/webCrawler.png";
import taskMapperPicture from "../Components/img/taskMapper.png";
import connectFourPicture from "../Components/img/connectFour.png";


export default class Projects extends React.Component {

    bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

  render() {
    return (
      <div className="Wrapper">
        <motion.h1 className="text-3xl font-bold text-white text-center my-10" whileHover={() => this.bandy()}>Projects</motion.h1>
            <table class = "flex justify-center items-center w-full h-full">
                <tbody>
                    <tr>
                        <td>
                            <ChessProject/>
                        </td>
                        <td>
                            <TaskMapperProject/>
                        </td>
                        <td>
                            <ExerciseTracker/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <WebCrawler/>
                        </td>
                        <td>
                            <SuggestionSystem/>
                        </td>
                        <td>
                            <DocuMorph/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                          <ConnectFour/>
                        </td>
                        <td>
                          <Pictionary/>
                        </td>
                      </tr>
                </tbody>
            </table>
      </div>
    );
  }
}

function ChessProject() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
    const bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    return (
      <>
        <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

            <Image
                className="object-cover w-full h-full"
                src={chessPicture}
            />
        </Button>
        <Modal 
          backdrop="opaque" 
          isOpen={isOpen} 
          onOpenChange={onOpenChange}
          radius="lg"
          size="3xl"
          classNames={{
            body: "py-6",
            backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
            base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
            header: "border-b-[1px] border-[#292f46]",
            footer: "border-t-[1px] border-[#292f46]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">Chess</ModalHeader>
                <ModalBody>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Technologies Used:
                    </motion.h1>
                     <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                        C++, X11, Git, Make, Vim, GDB, and Valgrind
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Description
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}>
                        
                        Teamed up with two classmates to develop an engaging chess game in C++. Our project went beyond the conventional, focusing on programming excellence and innovation. We implemented a robust computer opponent, enhancing the gaming experience. Utilizing design patterns like observer and factory in object-oriented programming, we created a modular and extensible codebase.

                        Harnessing the power of X11, we designed a visually immersive interface, elevating the chess experience. Our commitment to user experience is evident in the intuitive and enjoyable platform we crafted. This collaborative coding effort showcases our proficiency in C++ and adept integration of advanced programming concepts, resulting in a sophisticated digital chess arena that seamlessly blends strategy with innovation.
                    
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Learning Outcomes
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                        This project was a valuable learning experience, providing a deep dive into the world of C++ programming. We honed our skills in object-oriented programming, design patterns, and software architecture. Our hands-on experience with X11 and Git further enriched our knowledge, equipping us with practical expertise in software development. This project was a testament to our ability to collaborate effectively, communicate ideas, and deliver a high-quality product.
                    </motion.p>
                </ModalBody>
                <ModalFooter>
                  <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  }

function TaskMapperProject() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
    const bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    return (
      <>
        <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

            <Image
                className="object-cover w-full h-full"
                src={taskMapperPicture}
            />
        </Button>
        <Modal 
          backdrop="opaque" 
          isOpen={isOpen} 
          onOpenChange={onOpenChange}
          radius="lg"
          size="3xl"
          classNames={{
            body: "py-6",
            backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
            base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
            header: "border-b-[1px] border-[#292f46]",
            footer: "border-t-[1px] border-[#292f46]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">Task Mapper</ModalHeader>
                <ModalBody>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Technologies Used:
                    </motion.h1>
                     <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                      Angular, TypeScript, APIs (MapTiler), HTML, CSS, JavaScript, VS Code and Git
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Description
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}>
                    Developed a user-friendly Task Mapping website using Angular, TypeScript, and MapTiler APIs to allow users to visually map out their work assignments. Inspired by the practical need to navigate various classes, the app provides an intuitive interface for users to identify and organize tasks based on specific locations.
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Learning Outcomes
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                      Through this experience, I gained proficiency in key technologies, including Angular, TypeScript, HTML, CSS, and JavaScript, enabling me to create dynamic and interactive web applications. The project specifically involved integrating MapTiler APIs for geographical mapping functionality within the application.

                      My achievements extend to designing an intuitive user interface with an emphasis on spatial considerations for mapping tasks based on specific geographical locations. I adeptly utilized Visual Studio Code (VS Code) as the primary integrated development environment and implemented version control using Git for collaborative development.
                    </motion.p>
                </ModalBody>
                <ModalFooter>
                  <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  }

function ExerciseTracker() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
    const bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    return (
      <>
        <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

            <Image
                className="object-cover w-full h-full"
                src={ExercisePicture}
            />
        </Button>
        <Modal 
          backdrop="opaque" 
          isOpen={isOpen} 
          onOpenChange={onOpenChange}
          radius="lg"
          size="3xl"
          classNames={{
            body: "py-6",
            backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
            base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
            header: "border-b-[1px] border-[#292f46]",
            footer: "border-t-[1px] border-[#292f46]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">Exercise Tracker</ModalHeader>
                <ModalBody>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Technologies Used:
                    </motion.h1>
                     <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                      MongoDB, Express, React, Node (MERN Stack)
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Description
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}>
                    I crafted a sleek Exercising Tracking Website with the MERN stack, driven by my love for gym workouts. This platform offers a user-friendly interface powered by React, making it effortless for users to log and track their exercises. Utilizing MongoDB for data storage and Express.js/Node.js for the backend ensures seamless real-time updates and a responsive design, enhancing the user experience. This project blends my passion for fitness with cutting-edge technology, providing a personalized and motivating tool for exercise enthusiasts.
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Learning Outcomes
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                    Creating the Exercising Tracking Website led me on a journey of learning the MERN stack from scratch. MongoDB taught me the ins and outs of NoSQL databases, while Express.js and Node.js sharpened my server-side development skills. React became my toolkit for building a dynamic frontend, allowing me to create an engaging user interface. This hands-on experience not only enhanced my proficiency in the MERN stack but also fueled my passion for crafting seamless and user-friendly applications. 
                    </motion.p>
                </ModalBody>
                <ModalFooter>
                  <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  }

function WebCrawler() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
    const bandy = () => {
        return {
        transform : [
            "scale3d(1,1,1)",
            "scale3d(.75,1.25,1)",
            "scale3d(.95,1.05,1)",
            "scale3d(1,1,1)",
        ],
        transition: {duration: 0.3},
        color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
      };} 

    return (
      <>
        <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

            <Image
                className="object-cover w-full h-full"
                src={webCrawlerPicture}
            />
        </Button>
        <Modal 
          backdrop="opaque" 
          isOpen={isOpen} 
          onOpenChange={onOpenChange}
          radius="lg"
          size="3xl"
          classNames={{
            body: "py-6",
            backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
            base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
            header: "border-b-[1px] border-[#292f46]",
            footer: "border-t-[1px] border-[#292f46]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">WebCrawler</ModalHeader>
                <ModalBody>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Technologies Used:
                    </motion.h1>
                     <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                      MongoDB, Node, Puppeteer, JavaScript, Git, Jira and VS Code
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Description
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}>
                    Developed a powerful web crawler leveraging MongoDB, Node.js, Puppeteer, JavaScript, Git, and VS Code. This sophisticated tool efficiently navigates websites, extracting targeted data using Puppeteer for web scraping. The extracted information is then seamlessly stored in a MongoDB database, providing a robust and organized data bank. The use of Git for version control ensures collaborative development, while Visual Studio Code serves as the primary integrated development environment, offering a streamlined coding experience. This project not only showcases my proficiency in web crawling technologies but also underscores my commitment to creating effective solutions for data extraction and storage.
                    </motion.p>
                    <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                        Learning Outcomes
                    </motion.h1>
                    <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>  
                      I developed a web crawler using MongoDB, Node.js, Puppeteer, JavaScript, Git, and VS Code. This hands-on project improved my skills in web scraping, data extraction, and storage. I deepened my understanding of JavaScript, collaborated effectively using Git, and enhanced my coding efficiency with Visual Studio Code. This experience not only showcased my technical abilities but also emphasized problem-solving and the importance of thorough documentation for future projects.
                    </motion.p>
                </ModalBody>
                <ModalFooter>
                  <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  }

function SuggestionSystem() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
  const bandy = () => {
      return {
      transform : [
          "scale3d(1,1,1)",
          "scale3d(.75,1.25,1)",
          "scale3d(.95,1.05,1)",
          "scale3d(1,1,1)",
      ],
      transition: {duration: 0.3},
      color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
    };} 

  return (
    <>
      <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

          <Image
              className="object-cover w-full h-full"
              src={SuggestionPicture}
          />
      </Button>
      <Modal 
        backdrop="opaque" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        radius="lg"
        size="3xl"
        classNames={{
          body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">Suggestion System</ModalHeader>
              <ModalBody>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Technologies Used:
                  </motion.h1>
                   <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                    C, GDB, Valgrind, Git, Linux, Bash and VS Code
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Description
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}>
                  Developed a highly efficient document translator in C, showcasing robust programming and language processing skills. The project features a custom dictionary system that optimizes API usage, ensuring cost-effective and accurate word-by-word translations. Additionally, efficient scanning algorithms were implemented to seamlessly translate documents into various languages. The combination of custom optimization and advanced algorithms in DocMorpher reflects my commitment to creating effective and precise language translation solutions.
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Learning Outcomes
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>  
                    Creating DocMorpher significantly boosted my skills in C programming, emphasizing efficiency and optimization. I successfully implemented a custom dictionary system, mastering the art of cost-effective and accurate word-by-word translations through API optimization. This hands-on experience refined my language processing abilities, enabling seamless document translation into various languages using efficient scanning algorithms. The project taught me practical problem-solving and the importance of clear documentation. In essence, DocMorpher not only advanced my technical expertise but also underscored the real-world application of language processing skills and optimization strategies.
                  </motion.p>
              </ModalBody>
              <ModalFooter>
                <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

function DocuMorph() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
  const bandy = () => {
      return {
      transform : [
          "scale3d(1,1,1)",
          "scale3d(.75,1.25,1)",
          "scale3d(.95,1.05,1)",
          "scale3d(1,1,1)",
      ],
      transition: {duration: 0.3},
      color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
    };} 

  return (
    <>
      <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

          <Image
              className="object-cover w-full h-full"
              src={DocuMorphPicture}
          />
      </Button>
      <Modal 
        backdrop="opaque" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        radius="lg"
        size="3xl"
        classNames={{
          body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">DocuMorph</ModalHeader>
              <ModalBody>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Technologies Used:
                  </motion.h1>
                   <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                    TypeScript, React, Node.js, Express, MongoDB, Git, HuggingFace LLMS, Docker, and VS Code
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Description
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}>
                  Crafted an intuitive user interface for effortless interaction, enabling users to input text and promptly receive accurate suggestions in real-time. Leveraged advanced algorithms to calculate semantic similarity between user-inputted text and existing data in the MongoDB database, ensuring contextually relevant and meaningful suggestions. This project not only showcases proficiency in LLMs, TypeScript, Python, React, and REST APIs but also highlights a keen focus on user experience and the application of sophisticated algorithms for precise suggestion generation.
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Learning Outcomes
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>  
                    In crafting the Suggestion System, I enhanced my expertise in designing an intuitive user interface for effortless input and real-time suggestion delivery. Proficient in TypeScript, Python, React, MongoDB, REST APIs, and Docker, I developed a comprehensive system that calculates semantic similarity for contextually relevant suggestions. Managing the MongoDB database, real-time data interaction, and collaborative development added depth to my skills. Tackling algorithmic complexity and incorporating emerging technologies, such as LLMs, showcased my problem-solving abilities. The project not only reflects technical proficiency but also emphasizes a commitment to user-friendly and contextually precise solutions.
                  </motion.p>
              </ModalBody>
              <ModalFooter>
                <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
 }

function ConnectFour() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
  const bandy = () => {
      return {
      transform : [
          "scale3d(1,1,1)",
          "scale3d(.75,1.25,1)",
          "scale3d(.95,1.05,1)",
          "scale3d(1,1,1)",
      ],
      transition: {duration: 0.3},
      color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
    };} 

  return (
    <>
      <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

          <Image
              className="object-cover w-full h-full"
              src={connectFourPicture}
          />
      </Button>
      <Modal 
        backdrop="opaque" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        radius="lg"
        size="3xl"
        classNames={{
          body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">Connect Four</ModalHeader>
              <ModalBody>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Technologies Used:
                  </motion.h1>
                   <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                     Java, Eclipse, Git, JSwing, and VS Code
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Description
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}> 
                    Crafted a Connect Four game using Java, Eclipse, Git, JSwing, and VS Code. Proficient in Java programming, Eclipse facilitated efficient coding, while Git enabled collaborative development. JSwing was employed for an intuitive graphical interface, ensuring an engaging user experience. VS Code complemented the process with its versatility. This project showcases my ability to create interactive applications by leveraging diverse tools and frameworks.
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Learning Outcomes
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>  
                  Building the Connect Four game honed my Java programming skills in Eclipse, emphasizing efficient coding and debugging. Git streamlined collaborative development, maintaining an organized codebase. JSwing facilitated an intuitive graphical interface, and Visual Studio Code added versatility. Beyond coding, the project enhanced teamwork, problem-solving, and project management skills. This experience reflects my dedication to crafting engaging and user-friendly applications.
                   </motion.p>
              </ModalBody>
              <ModalFooter>
                <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
 }

function Pictionary() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  
  const bandy = () => {
      return {
      transform : [
          "scale3d(1,1,1)",
          "scale3d(.75,1.25,1)",
          "scale3d(.95,1.05,1)",
          "scale3d(1,1,1)",
      ],
      transition: {duration: 0.3},
      color : ["#FFFFFF","#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#00FFFF", "#FFFF00", "#8457ff", "#FFFFFF"],
    };} 

  return (
    <>
      <Button onPress={onOpen} color="secondary invisible" className="w-full h-1/3">

          <Image
              className="object-cover w-full h-full"
              src={pictionaryPicture}
          />
      </Button>
      <Modal 
        backdrop="opaque" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        radius="lg"
        size="3xl"
        classNames={{
          body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-white font-bold text-xl">Pictionary</ModalHeader>
              <ModalBody>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Technologies Used:
                  </motion.h1>
                   <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>
                     p5.js, HTML, CSS, JavaScript, and VS Code
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Description
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-s"  whileHover={() => bandy()}> 
                  Inspired by a pivotal moment in my journey of self-discovery, I embarked on creating a Pictionary web app using p5.js, HTML, CSS, JavaScript, and VS Code. The genesis of this project stemmed from a challenge posed by my mom, tasked with hosting a remote corporate event. Seeking a solution for playing Pictionary virtually, I recognized the need for software that could accommodate drawing and incorporate a timer seamlessly. Leveraging my budding interest in software development, I undertook the challenge to develop a web app that fulfilled her requirements. Since that transformative day, I've been captivated by the potential of technology to offer practical solutions to real-world problems. This project not only showcased my technical proficiency but also ignited a passion for using technology to address practical challenges in innovative ways.  
                  </motion.p>
                  <motion.h1 className="text-white my-1 mr-10 text-justify font-bold text-m"  whileHover={() => bandy()}>
                      Learning Outcomes
                  </motion.h1>
                  <motion.p className="text-white my-1 mr-10 text-justify"  whileHover={() => bandy()}>  
                  Creating the Pictionary web app was a journey that expanded my technical skills in p5.js, HTML, CSS, and JavaScript. I learned to address specific challenges, like incorporating real-time drawing features and implementing an effective timer for a remote corporate event. This project deepened my understanding of user-friendly interfaces and honed my problem-solving skills, emphasizing the practical application of technology to real-world needs. Overall, it was a transformative experience that ignited my passion for innovative solutions in software development.
                  </motion.p>
              </ModalBody>
              <ModalFooter>
                <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
 }