import logo from './logo.svg';
import React from 'react';
import {User} from "@nextui-org/react";
import {Tabs, Tab, Card, CardBody} from "@nextui-org/react";
import './App.css';

import MyNavbar from "./Components/navbar.component";
import Hero from './Components/hero.component';
import AboutMe from "./Components/aboutme.component";
import Experience from "./Components/experience.component";
import Education from "./Components/education.component";
import Skills from './Components/skills.component';
import Projects from './Components/projects.component';
import Awards from './Components/awards.component';
import ContactMe from './Components/contactme.component';

function App() {
  return (
    <div className="App">
      <div className = "desktop">
      <MyNavbar />
      <section id="divHero" className="hero">
        <Hero />
      </section>
      <section id="AboutMe">
        <AboutMe />
      </section>
      <section id="Experience">
        <Experience />
      </section>
      <section id="Education"> 
        <Education />
      </section>
      <section id="Skills"> 
        <Skills />
      </section>
      <section id="Projects">
        <Projects />
      </section>
      <section id="Awards">
        <Awards />
      </section>
      <section id="ContactMe"> 
        <ContactMe />
      </section>
      </div>
      <div className = "mobile">
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center">
          <h1 className="text-3xl font-bold text-white text-center hero my-10">Optimized for desktop browsing. For the best experience, please return on a larger screen. Thank you!</h1>
        </div>
      </div>
      </div>
    </div>
  );
}

export default App;
